import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import chevronLeftBlack from "images/layout/chevron-left-black.svg"
import StripeJsContainer from "./stripe_js_container"
import PaymentInfoForm from "./payment_info_form"
import Success from "./success"
import ChevronUpIcon from "shared/components/icons/chevron_up_icon"
import ChevronDownIcon from "shared/components/icons/chevron_down_icon"
import paidBenefits from "app/components/subscriptions/shared/paid_benefits"
import ErrorBoundary from "shared/components/error_boundary"

import "./sign_up_and_purchase.scss"

export default function SignUpAndPurchase(props) {
  useEffect(() => {
    if (props.inactiveUserAppletsDisabled) window.App.Utils?.logCustomDatadogAction("inactive_applets_disabled")
    window.App.Utils?.logCustomDatadogAction("stripe_checkout_viewed")
  }, [])

  const [subscription, setSubscription] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [showBenefits, setShowBenefits] = useState(false)

  const benefits = paidBenefits[props.tier]

  let tierName
  switch (props.tier) {
    case "pro":
      tierName = "Pro+"
      break
    case "intermediate_pro":
      tierName = "Pro"
  }

  const studentProgramCheckout = props.tier === "pro" && props.userIsStudent

  return (
    <>
      {subscription ? (
        <ErrorBoundary>
          <Success
            urls={props.urls}
            subscription={{
              isTrialing: subscription.status === "trialing",
              expiresAt: subscription.expires_at,
              plan: subscription.plan_id,
            }}
            permissions={permissions}
            subscriptionMobileSuccessPath={
              props.redirectToMobileAppOnSuccess ? "ifttt://pro_subscription?status=subscribed" : null
            }
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <StripeJsContainer>
            <header styleName="onboarding-header" className="pb2 pb0--phone">
              <div styleName="spacer">
                {!props.renderingInsideModal && (
                  <button
                    type="submit"
                    className="button-tertiary button-outlined button-with-icon only-icon-on-mobile"
                    aria-label="Back button"
                    data-dd-action-name="Subscription back button"
                    onClick={() => {
                      window.history.back()
                    }}
                  >
                    <span className="button-icon">{parse(chevronLeftBlack)}</span>
                    <span className="hide-on-xs">Back</span>
                  </button>
                )}
              </div>
              <h1>
                <img src={props.assets.iftttSvgUrl} alt="IFTTT logo" width="122" height="37" />
                {props.tier === "pro" ? (
                  <img src={props.assets.proPlusBadgeSvgUrl} alt="Pro+ badge" width="91" height="53" />
                ) : (
                  <img src={props.assets.proBadgeSvgUrl} alt="Pro badge" width="91" height="53" />
                )}
              </h1>
              <div styleName="spacer" />
            </header>
            <section styleName="benefits" className="welcome">
              <ul styleName={showBenefits ? "active" : ""}>
                {benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <div
                styleName="dropdown"
                role="button"
                onClick={() => {
                  setShowBenefits(!showBenefits)
                }}
              >
                {showBenefits ? "See less" : `See ${tierName} benefits`}
                <div styleName="chevron">{showBenefits ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
              </div>
              {props.showWarningForStudents && (
                <p>Students get their first year of Pro+ with 90% off. Verify your email to claim this deal.</p>
              )}
            </section>
            <div className="flexbox" styleName="payment-info">
              <PaymentInfoForm
                {...props}
                studentProgramCheckout={studentProgramCheckout}
                setSubscription={setSubscription}
                setPermissions={setPermissions}
              />
            </div>
          </StripeJsContainer>
        </ErrorBoundary>
      )}
    </>
  )
}

SignUpAndPurchase.propTypes = {
  assets: PropTypes.shape({
    iftttSvgUrl: PropTypes.string.isRequired,
    proBadgeSvgUrl: PropTypes.string.isRequired,
    proPlusBadgeSvgUrl: PropTypes.string.isRequired,
  }),
  eligibleForTrial: PropTypes.bool,
  eligibleTrialPeriod: PropTypes.number,
  inactiveUserAppletsDisabled: PropTypes.bool,
  redirectToMobileAppOnSuccess: PropTypes.bool,
  renderingInsideModal: PropTypes.bool.isRequired,
  showWarningForStudents: PropTypes.bool,
  tier: PropTypes.string.isRequired,
  urls: PropTypes.object,
  userIsStudent: PropTypes.bool,
  initialInterval: PropTypes.string,
}

SignUpAndPurchase.defaultProps = {
  renderingInsideModal: false,
  showWarningForStudents: false,
  userIsStudent: false,
}
