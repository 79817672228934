import { useEffect, useReducer, useState } from "react"
import uniqBy from "lodash/uniqBy"
import moment from "moment"
import classNames from "classnames"
import parse from "html-react-parser"

import BackgroundColorContext from "./context"

import AIIcon from "images/diy/ai-composer.svg"
import Spinner from "shared/components/spinner"
import ServiceSelector from "./service_selector"
import StepSelector from "./step_selector"
import ServiceConnection from "./service_connection"
import Preview from "./preview"
import FilterCode from "./filter_code"
import Delay from "./delay"
import { creationModeOptions, previousStep, steps, stepsTitle } from "./steps"
import { formatStep, initialState, reducer } from "./reducer"
import Header from "./header"
import AutomateWithAI from "./automate_with_ai"
import AutomateWithAIPreview from "./automate_with_ai_preview"
import InitialStep from "./initial_step"
import StepFieldsSelector from "./step_fields_selector"
import {
  DIYLoadServiceActionsQuery,
  DIYLoadServiceQueriesQuery,
  DIYLoadServiceTriggersQuery,
} from "./queries"
import AppletLimitWarning from "./applet_limit_warning"
import MakeItYourOwnModal from "./make_it_your_own_modal"
import UpgradePlanModal from "../upgrade_plan_modal"
import MobileGateModal from "./filter_code/mobile_gate_modal"

import colors from "foundation/_colors_export.scss?variables"

import "./index.scss"
import {
  loadPreview,
  loadPrivateServices,
  fetchStepFieldsAndIngredients,
  fetchStepDefaultValues,
} from "./api"
import { ingredientsMetadataFromState } from "./utils/ingredients_metadata_from_state"
import { selectedActionsAboveLimit } from "./utils/selected_actions_above_limit"
import { cancellationError, needsUpgradeError } from "./utils/error_map"
import { pluralizeStepType } from "shared/lib/utils"
import {
  NormalizedApplet,
  Action,
  Query,
  Trigger,
  User,
  StepField,
  StoredFieldProps,
  StepType,
  Service,
  TQAType,
  LiveChannels,
  UIStepField,
} from "./utils/interfaces"

import ErrorBoundary from "shared/components/error_boundary"
import FilterCodeModal from "./filter_code_modal"
import modalCopy, { CheckoutStep } from "app/components/upgrade_plan_modal/modal_copy"

interface RecommendedServices {
  triggers: Array<string>
  queries: Array<string>
  actions: Array<string>
}

interface Props {
  importedState: {
    normalized_applet: NormalizedApplet
    id: string
    applet_trigger: { trigger: Trigger }
    applet_actions: Array<{ action: Action }>
    applet_queries: Array<{ query: Query }>
    filter_code: string
  }
  filterCodeTemplate: string
  recommendedServices: RecommendedServices
  preloadedServices: Array<Service>
  allServiceCategories: Array<string>
  user: User
  urls: {
    activationPath: string
    connectionFinishedUrl: string
    newProPlusSubscriptionUrl: string
    newProSubscriptionUrl: string
    modalSubscriptionUrl: string
  }
  mobile_device: string
  msa: boolean
  serviceSuggestion: string
  personalAppletsCount: number
  proPlusProductId: string
}

interface UpgradeToProState {
  showModal: boolean
  step: string
  stepTier?: null | string
  trackObjectId?: string
}

interface MakeItYourOwnState {
  showModal: boolean
  onConvert?: () => void
  onCancel?: () => void
  onClose?: () => void
}

const isUserConnected = (service: Service) => service.live_channels?.some(lc => !lc.offline)

const DIY = ({
  importedState,
  filterCodeTemplate,
  allServiceCategories,
  preloadedServices,
  recommendedServices,
  urls,
  mobile_device = "none",
  msa = false,
  serviceSuggestion,
  personalAppletsCount,
  proPlusProductId,
  user: propsUser,
}: Props) => {
  const [makeItYourOwn, updateMakeItYourOwn] = useState<MakeItYourOwnState>({
    showModal: false,
    onConvert: () => {},
    onCancel: () => {},
    onClose: () => {},
  })
  const [upgradeToPro, updateUpgradeToPro] = useState<UpgradeToProState>({
    showModal: false,
    step: "queries",
    stepTier: null,
    trackObjectId: "",
  })
  const [filterCodeModal, updateFilterCodeModal] = useState<{ showModal: boolean }>({
    showModal: false,
  })
  const [showFilterCodeMobile, updatefilterCodeMobileVisibility] = useState<boolean>(false)

  const urlParams = new URLSearchParams(window.location.search)
  const initialCreationMode = urlParams.has("ai") ? creationModeOptions.AUTOMATE_WITH_AI : creationModeOptions.DIY
  const [creationModeOption, updateCreationModeOption] = useState<number>(initialCreationMode)
  const [openAIMessage, updateOpenAIMessage] = useState<string>("")

  const [state, dispatch] = useReducer(reducer, {
    ...initialState({ user: propsUser }),
    loading: !!importedState,
    editing: !!importedState,
  })

  const user = state.user

  const userOwned = importedState?.normalized_applet?.author_id === window.App.user?.id

  const publishedByCurrentUser = userOwned && importedState?.normalized_applet?.published === true

  const publishedAppletData = {
    publishedAt: moment.utc(new Date(importedState?.normalized_applet?.created_at?.substring(0, 10))).format("LL"),
    enabledCount: importedState?.normalized_applet?.installs_count,
  }

  const userAtQuota = user.appletQuota.total >= 0 && user.appletQuota.remaining === 0

  useEffect(() => {
    const effect = async () => {
      const privateServices = await loadPrivateServices()
      const allServices = privateServices.concat(preloadedServices)
      const suggestedServices = serviceSuggestion
        ? allServices.filter((s: { module_name: string }) => s.module_name === serviceSuggestion)
        : []
      onServicesLoaded(uniqBy(suggestedServices.concat(allServices), (s: { module_name: string }) => s.module_name))
      if (!state.editing) {
        window.App.Utils?.logCustomDatadogAction?.("diy_applet_maker_tab")
      }
      dispatch({ type: "LOADING", value: false })
    }
    effect()
  }, [])

  const onServicesLoaded = (services: Array<Service>) => {
    dispatch({
      type: "SERVICES_LOADED",
      value: services,
    })

    if (importedState) {
      const { id, normalized_applet, applet_trigger, applet_actions, applet_queries } = importedState

      const trigger = applet_trigger.trigger
      const actions = applet_actions.map(a => a.action)
      const queries = applet_queries.map(q => q.query)

      const formatStepForOverride = (step: TQAType, type: string) => {
        let owner = `/${pluralizeStepType(type)}/${step.full_module_name}`
        if (type === "action") {
          const index = actions.filter(a => a.full_module_name === step.full_module_name).indexOf(step as Action)
          owner += `/${index}`
        }

        let fields: { [key: string]: object } = {}
        const storedFields = normalized_applet.stored_fields.filter(f => {
          return f.owner === owner
        })

        const liveChannelField = storedFields.find(sf => sf.name === "_live_channel_id")
        if (liveChannelField) {
          fields["_live_channel_id"] = liveChannelField
        }

        fields = step[`${type}_fields`].reduce(
          (memo: { [key: string]: object }, f: { name: string }, order: number) => {
            const storedField = storedFields.find(sf => sf.name === f.name)

            if (!storedField) {
              memo[f.name] = {
                ...f,
                order,
              }
              return memo
            }

            let currentValue = storedField.value
            if (storedField.is_hidden || storedField.value === null) {
              currentValue = storedField.default_value
            }

            memo[f.name] = {
              ...f,
              order,
              hidden: storedField.is_hidden,
              owner: storedField.owner,
              value: currentValue,
            }
            return memo
          },
          fields
        )

        const service: Service = services.find(s => s.id === step.channel.id) as Service
        service.allow_multiple_live_channels = step.channel.allow_multiple_live_channels
        service.live_channels = step.channel.live_channels

        step.live_channel_id = normalized_applet.permissions.find(p => p.id === owner)?.live_channel.id as string

        return formatStep({ step, fields }, type, service)
      }

      dispatch({
        type: "SET_OVERRIDE_STATE",
        value: {
          appletId: id,
          name: normalized_applet.name,
          selectedTrigger: formatStepForOverride(trigger, "trigger"),
          selectedQueries: queries.map(q => formatStepForOverride(q, "query")),
          selectedActions: actions.map(a => formatStepForOverride(a, "action")),
          filterCode: importedState.filter_code,
          delay: normalized_applet.actions_delay,
        },
      })
    }
  }

  // Check every time that trigger and first action changed.
  useEffect(() => {
    if (state.selectedTrigger !== null && state.selectedActions.length > 0) {
      const loadPreviewMutation = async () => {
        try {
          const response = await loadPreview({
            trigger: state.selectedTrigger.full_module_name,
            triggerFields: state.selectedTrigger.fields,
            triggerLiveChannelId: state.selectedTrigger.live_channel_id,
            action: state.selectedActions[0].full_module_name,
            actionFields: state.selectedActions[0].fields,
            actionLiveChannelId: state.selectedActions[0].live_channel_id,
          })

          updatePreview({
            name: response.statementPreview.normalized_applet.name || "",
            brandColor: response.statementPreview.normalized_applet.brand_color,
            serviceSlug: response.statementPreview.normalized_applet.service_slug,
          })
        } catch (error) {
          updatePreview({
            name: "",
            serviceSlug: state.selectedTrigger.service.module_name,
            brandColor: state.selectedTrigger.service.brand_color,
          })
        }
      }

      loadPreviewMutation()
    }
  }, [state.selectedTrigger, state.selectedActions[0]])

  const goBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      value: previousStep(state),
    })
  }

  useEffect(() => {
    window.addEventListener("popstate", handlePopState)

    return function cleanup() {
      window.removeEventListener("popstate", handlePopState)
    }
  }, []) // Register listeners once

  const handlePopState = (event: Event) => {
    if (state.step !== steps.INITIAL) {
      event.preventDefault()
      goBack()
    }
  }

  useEffect(() => {
    if (state.dataChanged) {
      window.addEventListener("beforeunload", handleBeforeUnload, {
        once: true,
      })

      // cleanup will be calling in the next re-render execution. It doesn't happen when user finish DIY creation.
      // It's necessary to call manually to remove event listener when redirect user to applet page (using location.assign)
      return cleanupBeforeUnload
    }
  })

  const handleBeforeUnload = (event: Event) => {
    dispatch({ type: "LOADING", value: false })

    // If the user isn't explicitly saving the Applet, warn them before leaving the page
    if (!state.saving) {
      event.preventDefault()
      event.returnValue = "You have unsaved changes. Do you want to leave this page and discard your changes?"
    }
  }

  const cleanupBeforeUnload = function cleanup() {
    window.removeEventListener("beforeunload", handleBeforeUnload)
  }

  const makeItYourOwnFeaturePrompt = async () => {
    const communityApplet = state.editing && !(userOwned || state.makingItTheirOwn)

    if (!communityApplet) {
      return Promise.resolve()
    }

    const showUpgradeModal = (step: string) => {
      updateUpgradeToPro({
        showModal: true,
        step,
      })
      return Promise.reject(needsUpgradeError)
    }

    if (!["legacy_pro", "pro"].includes(user.tier) && importedState?.normalized_applet?.pro_features) {
      await showUpgradeModal("convert_pro_plus_applet")
    } else if (importedState.normalized_applet.intermediate_pro_features && user.tier === "free") {
      await showUpgradeModal("multi_action")
    } else if (!["legacy_pro", "pro"].includes(user.tier) && personalAppletsCount >= user.appletQuota.total) {
      await showUpgradeModal("applet_quota")
    }

    return new Promise((resolve: (value?: unknown) => void, reject: (reason?: unknown) => void) => {
      updateMakeItYourOwn({
        showModal: true,
        onConvert: () => {
          dispatch({
            type: "MAKING_IT_THEIR_OWN",
          })
          window.appendFlash("Converted to your own Applet", "success", resolve)
        },
        onCancel: () => reject(cancellationError),
      })
    })
  }

  const proFeatureGate = async (permissionName: string) => {
    if (permissionName != "multi_service_account") {
      return Promise.resolve()
    }

    if (user.permissions[permissionName]?.permitted) {
      return Promise.resolve()
    } else {
      updateUpgradeToPro({
        showModal: true,
        step: permissionName,
      })
      return Promise.reject(cancellationError)
    }
  }

  const mobileFilterCodeGate = async () => {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 1000) {
      updatefilterCodeMobileVisibility(true)
      return Promise.reject(cancellationError)
    }

    return Promise.resolve()
  }

  const updatePreview = (value: { name: string; serviceSlug: string; brandColor: string }) => {
    dispatch({
      type: "UPDATE_PREVIEW",
      value,
    })
  }

  const updateLoading = (value: boolean) => dispatch({ type: "LOADING", value })

  const selectingTandaFieldsProps = () => ({
    step: state.selectedStep,
    sourceAppletId: state.appletId,
    stepErrors: state.errors,
    editMode: state.editing,
    addingStep: state.addingStep,
    userOwned,
    userAllowMultipleLiveChannels: user.permissions.multi_service_account.permitted === true,
    makeItYourOwnFeaturePrompt,
    makingItTheirOwn: state.makingItTheirOwn,
    connectionFinishedUrl: urls.connectionFinishedUrl,
    changeServiceAccount: (live_channel_id: string) => dispatch({ type: "CHANGE_SERVICE_ACCOUNT", live_channel_id }),
    updateServiceAccountList: (live_channels: Array<LiveChannels>) =>
      dispatch({ type: "UPDATE_SERVICE_ACCOUNT_LIST", live_channels }),
    proFeatureGate,
  })

  const handleConfigureStepFields = (type: string, step: TQAType) => {
    const stepFields = step[`${type}_fields`] as UIStepField[]

    // On new mode (live_channel_id empty), choose first non-offline service
    if (!step.live_channel_id) {
      step.live_channel_id = step.service.live_channels?.find(lc => !lc.offline)?.id as string
    }

    // DIY new Applets doesn't have stored fields and need to build the memory object in JS.
    // For all other cases, it is provided in the stored fields by Web::ConnectSDKFlow.inject_field_for_service_accounts.
    if (
      !stepFields.some(sf => sf.field_ui_type === "service_account_select") &&
      state.connectingService.allow_multiple_live_channels
    ) {
      stepFields.unshift({
        field_ui_type: "service_account_select",
        helper_text: "",
        hideable: false,
        label: `${state.connectingService.name} account`,
        name: "_live_channel_id",
        order: -1,
        normalized_field_type: "option",
        required: true,
      })
    }

    if (stepFields.length > 0) {
      return dispatch({
        type: `SELECTING_${type.toUpperCase()}_FIELDS`,
        value: step,
      })
    }

    return dispatch({
      type: state.addingStep ? `${type.toUpperCase()}_ADDED` : `${type.toUpperCase()}_REPLACED`,
      value: { step, fields: [] },
    })
  }

  const handleStepSelect = async (type: string, step: TQAType) => {
    await applyFieldsAndIngredients(type, step)

    if (!isUserConnected(state.connectingService)) {
      return dispatch({
        type: `CONNECTING_${type.toUpperCase()}_SERVICE`,
        value: step,
      })
    }

    handleConfigureStepFields(type, step)
  }

  const applyFieldsAndIngredients = async (type: string, step: TQAType) => {
    updateLoading(true)
    const fieldsType = `${type}_fields`
    let fieldsResponse

    try {
      fieldsResponse = await fetchStepFieldsAndIngredients(type as StepType, step)
    } catch (e) {
      updateLoading(false)
      return
    }

    const {
      [type]: { ingredients, [fieldsType]: fields },
    } = fieldsResponse

    step[fieldsType] = fields
    if (ingredients) Object.assign(step, { ingredients })
    updateLoading(false)
  }

  const handleServiceConnection = (type: string, service: Service) => {
    dispatch({
      type: `${type.toUpperCase()}_SERVICE_CONNECTED`,
      live_channels: service.live_channels,
    })

    handleConfigureStepFields(type, {
      ...state.selectedStep,
      service: {
        ...state.selectedStep.service,
        live_channels: service.live_channels,
      },
    })
  }

  const logStepAdded = (name: string, data?: { [key: string]: string }) => {
    // only log when creating a new applet
    if (!state.editing) {
      window.App.Utils?.logCustomDatadogAction?.(name, data)
    }
  }

  const DIYCreationMode = creationModeOption == creationModeOptions.DIY
  const showSwitcher =
    !state.selectedTrigger &&
    state.selectedQueries.length == 0 &&
    state.selectedActions.length == 0 &&
    !state.filterCode &&
    !state.editing

  let currentStep
  switch (state.step) {
    case steps.INITIAL:
      currentStep = (
        <>
          {!state.editing && <AppletLimitWarning user={user} className="initial-step" />}
          {showSwitcher && (
            <ul styleName="creation-mode-switcher">
              <li styleName={classNames({ selected: DIYCreationMode })}>
                <a
                  onClick={() => {
                    updateCreationModeOption(creationModeOptions.DIY)
                  }}
                >
                  Classic
                </a>
              </li>
              <li styleName={classNames({ selected: !DIYCreationMode })}>
                <a
                  onClick={() => {
                    updateCreationModeOption(creationModeOptions.AUTOMATE_WITH_AI)
                  }}
                  {...(!state.editing && { "data-dd-action-name": "ai_applet_maker_tab" })}
                >
                  <span>{parse(AIIcon)}</span> AI
                </a>
              </li>
            </ul>
          )}
          <section styleName="mode-containers" style={{ display: DIYCreationMode ? "none" : "block" }}>
            <AutomateWithAI
              user={user}
              active={!DIYCreationMode}
              openAIMessage={openAIMessage}
              updateOpenAIMessage={updateOpenAIMessage}
              showPreview={value => {
                dispatch({
                  type: "PREVIEW_OPEN_AI_RESULT",
                  value,
                })
              }}
              showUpgradeModal={() =>
                updateUpgradeToPro({
                  showModal: true,
                  step: "ai_maker_rate_limit",
                  stepTier: "intermediate_pro",
                })
              }
            />
          </section>
          <section styleName="mode-containers" style={{ display: DIYCreationMode ? "block" : "none" }}>
            <InitialStep
              dispatch={dispatch}
              editing={state.editing}
              makeItYourOwnFeaturePrompt={makeItYourOwnFeaturePrompt}
              makingItTheirOwn={state.makingItTheirOwn}
              mobileFilterCodeGate={mobileFilterCodeGate}
              proFeatureGate={proFeatureGate}
              sourceAppletId={state.editing ? importedState.id : null}
              state={state}
              user={{
                tier: user.tier,
                permissions: user.permissions,
              }}
              userOwned={userOwned}
              onCreationSuccess={() => {
                // Remove warning about unsaved changes on redirect.
                cleanupBeforeUnload()
              }}
              showFilterCodeModal={() => {
                updateFilterCodeModal({ showModal: true })
              }}
              showUpgradeModal={(step: string) =>
                updateUpgradeToPro({
                  showModal: true,
                  step,
                })
              }
            />
          </section>
        </>
      )
      break
    case steps.SELECTING_TRIGGER_SERVICE:
      currentStep = (
        <ServiceSelector
          loading={state.loading}
          updateLoading={updateLoading}
          services={state.services}
          recommendedServiceModuleNames={recommendedServices?.triggers}
          allServiceCategories={allServiceCategories}
          onServiceSelectQuery={DIYLoadServiceTriggersQuery}
          onServiceSelect={(updatedService: Service) =>
            dispatch({
              type: "SELECTING_TRIGGER",
              value: updatedService,
            })
          }
          stepType={"trigger"}
        />
      )
      break
    case steps.SELECTING_ACTION_SERVICE:
      currentStep = (
        <ServiceSelector
          loading={state.loading}
          updateLoading={updateLoading}
          services={state.services}
          recommendedServiceModuleNames={recommendedServices?.actions}
          allServiceCategories={allServiceCategories}
          onServiceSelectQuery={DIYLoadServiceActionsQuery}
          onServiceSelect={(updatedService: Service) =>
            dispatch({
              type: "SELECTING_ACTION",
              value: updatedService,
            })
          }
          stepType={"action"}
        />
      )
      break
    case steps.SELECTING_QUERY_SERVICE:
      currentStep = (
        <ServiceSelector
          loading={state.loading}
          updateLoading={updateLoading}
          services={state.services}
          recommendedServiceModuleNames={recommendedServices?.queries}
          allServiceCategories={allServiceCategories}
          onServiceSelectQuery={DIYLoadServiceQueriesQuery}
          onServiceSelect={(updatedService: Service) =>
            dispatch({
              type: "SELECTING_QUERY",
              value: updatedService,
            })
          }
          stepType={"query"}
        />
      )
      break
    case steps.CONNECTING_TRIGGER_SERVICE:
      currentStep = (
        <ServiceConnection
          service={state.connectingService}
          connectionFinishedUrl={urls.connectionFinishedUrl}
          onServiceConnected={(service: Service) => {
            handleServiceConnection("trigger", service)
          }}
        />
      )
      break
    case steps.CONNECTING_ACTION_SERVICE:
      currentStep = (
        <ServiceConnection
          service={state.connectingService}
          connectionFinishedUrl={urls.connectionFinishedUrl}
          onServiceConnected={(service: Service) => {
            handleServiceConnection("action", service)
          }}
        />
      )
      break
    case steps.CONNECTING_QUERY_SERVICE:
      currentStep = (
        <ServiceConnection
          service={state.connectingService}
          connectionFinishedUrl={urls.connectionFinishedUrl}
          onServiceConnected={(service: Service) => {
            handleServiceConnection("query", service)
          }}
        />
      )
      break
    case steps.SELECTING_TRIGGER:
      currentStep = (
        <StepSelector
          onStepSelect={(step: TQAType) => {
            handleStepSelect("trigger", step)
          }}
          selectedSteps={[]}
          steps={state.connectingService.public_triggers}
          stepType="trigger"
          service={state.connectingService}
        />
      )
      break
    case steps.SELECTING_ACTION:
      currentStep = (
        <StepSelector
          onStepSelect={(step: TQAType) => {
            handleStepSelect("action", step)
          }}
          selectedSteps={msa ? selectedActionsAboveLimit(state.selectedActions) : state.selectedActions}
          steps={state.connectingService.public_actions}
          stepType="action"
          service={state.connectingService}
        />
      )
      break
    case steps.SELECTING_QUERY:
      currentStep = (
        <StepSelector
          onStepSelect={(step: TQAType) => {
            handleStepSelect("query", step)
          }}
          selectedSteps={state.selectedQueries}
          steps={state.connectingService.public_queries}
          stepType="query"
          service={state.connectingService}
        />
      )
      break
    case steps.SELECTING_TRIGGER_FIELDS:
      currentStep = (
        <StepFieldsSelector
          {...selectingTandaFieldsProps()}
          onCreate={(step: TQAType, fields: object) => {
            logStepAdded("diy_applet_maker_trigger_selected", { service: step.service.module_name })
            dispatch({
              type: state.addingStep ? "TRIGGER_ADDED" : "TRIGGER_REPLACED",
              value: { step, fields },
            })
          }}
          stepFields={state.selectedStep.trigger_fields}
          stepType="trigger"
          proFeatureGate={proFeatureGate}
        />
      )
      break
    case steps.SELECTING_ACTION_FIELDS:
      currentStep = (
        <ErrorBoundary
          placeholder={<h3>Sorry, there was a problem getting these action details; we are investigating!</h3>}
        >
          <StepFieldsSelector
            {...selectingTandaFieldsProps()}
            onCreate={(step: TQAType, fields: object) => {
              logStepAdded(`diy_applet_maker_action_${state.selectedActions.length + 1}_selected`, {
                service: step.service.module_name,
              })
              dispatch({
                type: state.addingStep ? "ACTION_ADDED" : "ACTION_REPLACED",
                value: { step, fields },
              })
            }}
            stepFields={state.selectedStep.action_fields}
            stepType="action"
            ingredientsMetadata={ingredientsMetadataFromState(state)}
            trigger={state.selectedTrigger}
            queries={state.selectedQueries}
            defaultValueLoader={(step: Action, stepType: StepType) =>
              fetchStepDefaultValues(state.selectedTrigger?.full_module_name, step, stepType)
            }
          />
        </ErrorBoundary>
      )
      break
    case steps.SELECTING_QUERY_FIELDS:
      currentStep = (
        <StepFieldsSelector
          {...selectingTandaFieldsProps()}
          onCreate={(step: TQAType, fields: object) => {
            logStepAdded(`diy_applet_maker_query_${state.selectedQueries.length + 1}_selected`, {
              service: step.service.module_name,
            })
            dispatch({
              type: state.addingStep ? "QUERY_ADDED" : "QUERY_REPLACED",
              value: { step, fields },
            })
          }}
          stepFields={state.selectedStep.query_fields}
          stepType="query"
          ingredientsMetadata={ingredientsMetadataFromState(state, false)}
          trigger={state.selectedTrigger}
          defaultValueLoader={(step: Query, stepType: StepType) =>
            fetchStepDefaultValues(state.selectedTrigger?.full_module_name, step, stepType)
          }
        />
      )
      break
    case steps.ADDING_FILTER_CODE:
    case steps.EDITING_FILTER_CODE:
      currentStep = (
        <FilterCode
          contextTemplate={filterCodeTemplate}
          selectedTrigger={state.selectedTrigger}
          selectedQueries={state.selectedQueries}
          selectedActions={state.selectedActions}
          filterCode={state.filterCode}
          updateFilterCode={(filterCode: string) => {
            logStepAdded("diy_applet_maker_filter_code_added")
            dispatch({ type: "FILTER_CODE_MODIFIED", value: filterCode })
          }}
        />
      )
      break
    case steps.ADDING_DELAY_ACTION:
    case steps.EDITING_DELAY_ACTION:
      currentStep = (
        <Delay
          delay={state.delay}
          updateDelay={delay => {
            logStepAdded("diy_applet_maker_delay_added")
            dispatch({ type: "DELAY_ACTION_MODIFIED", value: delay })
          }}
        />
      )
      break
    case steps.PREVIEW:
      currentStep = (
        <Preview
          actions={state.selectedActions}
          errors={state.errors}
          userAtQuota={userAtQuota}
          showAppletQuotaModal={() => {
            updateUpgradeToPro({ showModal: true, step: "applet_quota" })
          }}
          upgradeWarningCopy={
            userAtQuota
              ? modalCopy({
                  step: "applet_quota",
                  currentTier: user.tier,
                  eligibleForTrial: user.eligibleForTrial,
                  eligibleTrialPeriod: user.eligibleTrialPeriod,
                })
              : {}
          }
          onCreationError={(errors: object) =>
            dispatch({
              type: "STEP_CREATION_FAILED",
              value: errors,
            })
          }
          onCreationSuccess={() => {
            // Remove warning about unsaved changes on redirect.
            cleanupBeforeUnload()

            window.App.Utils?.logCustomDatadogAction?.("diy_applet_created", {user})

            window.history.pushState({}, "My Applets - IFTTT", "/")
          }}
          preview={state.preview}
          queries={state.selectedQueries}
          trigger={state.selectedTrigger}
          filterCode={state.filterCode}
          userLogin={user.login}
          delay={state.delay}
        />
      )
      break
    case steps.PREVIEW_OPEN_AI_RESULT:
      currentStep = (
        <AutomateWithAIPreview
          activationPath={urls.activationPath}
          appletName={openAIMessage}
          previewState={state.openAIResult}
          showProFeatures
          showUpgradeModal={(step: string, tier: string) =>
            updateUpgradeToPro({
              showModal: true,
              step,
              stepTier: tier,
            })
          }
          tryAgainHandler={() => goBack()}
          user={{
            tier: user.tier,
            permissions: user.permissions,
          }}
          userAtQuota={userAtQuota}
        />
      )
  }

  const stepsToShowBgColor = [
    steps.CONNECTING_TRIGGER_SERVICE,
    steps.SELECTING_TRIGGER,
    steps.SELECTING_TRIGGER_FIELDS,

    steps.CONNECTING_QUERY_SERVICE,
    steps.SELECTING_QUERY,
    steps.SELECTING_QUERY_FIELDS,

    steps.CONNECTING_ACTION_SERVICE,
    steps.SELECTING_ACTION,
    steps.SELECTING_ACTION_FIELDS,
  ]

  const contextColor = () => {
    const bGColorService = state.selectedStep?.service || state.connectingService
    if (stepsToShowBgColor.includes(state.step) && bGColorService) {
      return bGColorService.brand_color
    } else if (stepsToShowBgColor.includes(state.step)) {
      return colors.primaryTextColor // Fallback in case any issue in flow with service
    } else if (state.step === steps.PREVIEW) {
      return Object.entries(state.preview).length ? state.preview.brandColor : "white"
    } else {
      return "white"
    }
  }

  const appletFeatureFlags = {
    pro_features: importedState?.normalized_applet?.pro_features,
    intermediate_pro_features: importedState?.normalized_applet?.intermediate_pro_features,
  }

  const sectionStyleName = classNames("diy-composer", {
    "filter-code-view": [steps.ADDING_FILTER_CODE, steps.EDITING_FILTER_CODE].includes(state.step),
  })

  return (
    <BackgroundColorContext.Provider value={contextColor()}>
      <MakeItYourOwnModal
        visible={makeItYourOwn.showModal}
        onConvert={makeItYourOwn.onConvert}
        onCancel={makeItYourOwn.onCancel}
        onClose={() => {
          if (makeItYourOwn.onClose) {
            makeItYourOwn.onClose()
          }
          updateMakeItYourOwn({
            showModal: false,
          })
        }}
      />
      <UpgradePlanModal
        appletFeatureFlags={appletFeatureFlags}
        onClose={() => {
          if (upgradeToPro.step === "applet_quota" && DIYCreationMode) {
            window.history.back()
          } else {
            updateUpgradeToPro({
              showModal: false,
              step: "queries",
            })
          }
        }}
        onUpgrade={(checkoutModalTarget: string) => {
          checkoutModalTarget === "intermediate_pro"
            ? dispatch({ type: "SUCCESSFUL_PRO_UPGRADE" })
            : dispatch({ type: "SUCCESSFUL_PRO_PLUS_UPGRADE" })
        }}
        proPlusProductId={proPlusProductId}
        step={upgradeToPro.step as CheckoutStep}
        tierRequirement={upgradeToPro.stepTier as string}
        urls={urls}
        user={{
          eligibleForTrial: user.eligibleForTrial,
          eligibleTrialPeriod: user.eligibleTrialPeriod,
          isTrialing: user.isTrialing,
          subscriptionPaymentType: user.subscriptionPaymentType,
          tier: user.tier,
        }}
        visible={upgradeToPro.showModal}
      />
      <FilterCodeModal
        visible={filterCodeModal.showModal}
        onClose={() => {
          updateFilterCodeModal({ showModal: false })
        }}
      />
      <MobileGateModal
        device={mobile_device}
        onClose={() => updatefilterCodeMobileVisibility(!showFilterCodeMobile)}
        visible={showFilterCodeMobile}
      />
      <Header
        appletTier={(!DIYCreationMode && state.openAIResult?.enablement_rules?.minimum_tier) || state.appletTier}
        aiPreviewEnablementRules={state.openAIResult?.enablement_rules}
        editing={state.editing}
        user={user}
        userAtQuota={userAtQuota}
        updateLoading={updateLoading}
        title={stepsTitle(state)}
        step={state.step}
        backButtonTarget={goBack}
        showingCheckoutModal={upgradeToPro.showModal}
        showUpgradeModal={(step: string, stepTier = "intermediate_pro") =>
          updateUpgradeToPro({
            showModal: true,
            step,
            stepTier,
          })
        }
      />

      {state.step === steps.INITIAL && state.editing && userOwned ? (
        publishedByCurrentUser ? (
          <h6 className="published-applet-cta">
            Published on {publishedAppletData.publishedAt}, enabled by {publishedAppletData.enabledCount}{" "}
            {publishedAppletData.enabledCount === 1 ? "person" : "people"}{" "}
            <a className="link-inline open-in-new" href={urls.publishedAppletEditPath} target="_blank" rel="noreferrer">
              Edit Published Applet
            </a>
          </h6>
        ) : (
          <h6 className="published-applet-cta">
            Want to publish this Applet so anyone can use it?{" "}
            <a
              style={{ textDecoration: "underline" }}
              className="link-inline open-in-new"
              href={urls.publishedAppletEditPath}
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>
          </h6>
        )
      ) : null}

      <section styleName={sectionStyleName}>{state.loading ? <Spinner /> : currentStep}</section>
    </BackgroundColorContext.Provider>
  )
}

export default DIY
